/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const ToggleSizeGoogleDesktopBodyWidth = '32px'
export const ToggleSizeGoogleDesktopBodyHeight = '12px'
export const ToggleSizeGoogleDesktopKnob = '20px'
export const ToggleSizeGoogleMobileBodyWidth = '32px'
export const ToggleSizeGoogleMobileBodyHeight = '12px'
export const ToggleSizeGoogleMobileKnob = '20px'
export const ToggleSizeAppleDesktopBodyWidth = '32px'
export const ToggleSizeAppleDesktopBodyHeight = '20px'
export const ToggleSizeAppleDesktopKnob = '16px'
export const ToggleSizeAppleMobileBodyWidth = '32px'
export const ToggleSizeAppleMobileBodyHeight = '20px'
export const ToggleSizeAppleMobileKnob = '16px'
export const ToggleSizeDesktopLarge = '48px'
export const ToggleSizeDesktopMedium = '40px'
export const ToggleSizeDesktopSmall = '32px'
export const ToggleSizeMobileLarge = '48px'
export const ToggleSizeMobileMedium = '40px'
export const ToggleSizeMobileSmall = '32px'
export const ToggleBorderRadiusGoogleDesktopBody = '6px'
export const ToggleBorderRadiusGoogleDesktopKnob = '10px'
export const ToggleBorderRadiusGoogleMobileBody = '6px'
export const ToggleBorderRadiusGoogleMobileKnob = '10px'
export const ToggleBorderRadiusAppleDesktopBody = '10px'
export const ToggleBorderRadiusAppleDesktopKnob = '8px'
export const ToggleBorderRadiusAppleMobileBody = '10px'
export const ToggleBorderRadiusAppleMobileKnob = '8px'
export const ToggleColorUnselectedDefaultBody = '#0000001f'
export const ToggleColorUnselectedDefaultKnob = '#00000061'
export const ToggleColorUnselectedHoveredBody = '#0000001f'
export const ToggleColorUnselectedHoveredKnob = '#00000061'
export const ToggleColorUnselectedDisabledBody = '#0000000a'
export const ToggleColorUnselectedDisabledKnob = '#0000001f'
export const ToggleColorSelectedDefaultBody = '#ed93411f'
export const ToggleColorSelectedDefaultKnob = '#ED9341'
export const ToggleColorSelectedHoveredBody = '#ed93411f'
export const ToggleColorSelectedHoveredKnob = '#ED9341'
export const ToggleColorSelectedDisabledBody = '#0000000a'
export const ToggleColorSelectedDisabledKnob = '#0000001f'
