/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const BadgeSizeNumberOffDesktop = '8px'
export const BadgeSizeNumberOffMobile = '8px'
export const BadgeSizeNumberOnDesktop = '20px'
export const BadgeSizeNumberOnMobile = '20px'
export const BadgeColorPrimaryBackground = '#f57c00'
export const BadgeColorPrimaryText = '#ffffff'
export const BadgeColorSecondaryBackground = '#ED9341'
export const BadgeColorSecondaryText = '#ffffff'
export const BadgeColorTertiaryBackground = '#e0e0e0'
export const BadgeColorTertiaryText = '#212121'
export const BadgeColorBlankBackground = '#ffffff00'
export const BadgeColorBlankText = '#616161'
